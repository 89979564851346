import { BiCategory } from "react-icons/bi";
import { FaHospitalUser } from "react-icons/fa6";
import { TbBuildingHospital } from "react-icons/tb";

const icons = {  
  BiCategory,  
  TbBuildingHospital,
  FaHospitalUser
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.BiCategory,
      breadcrumbs: true,
      roles: [1]      
    },
    {
      id: "hospital",
      title: "Hospitals",
      type: "item",
      url: "/hospital",
      icon: icons.TbBuildingHospital,
      breadcrumbs: true,
      roles: [1]            
    },
    {
      id: "patient",
      title: "Patients",
      type: "item",
      url: "/patient",
      icon: icons.FaHospitalUser,
      breadcrumbs: true,      
      roles: [1]      
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1],
      display: false
    },    
    {
      id: "profile-edit",
      title: "Edit Profile",
      type: "item",
      url: "/profile/edit",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1],
      display: false
    }     
  ]
};

export default dashboard;