import { dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getHospital = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_HOSPITAL_REQUEST });            
      const { data } = await Axios.get(`/app/hospital/${id}`);
      dispatch({ 
        type: dataConstants.GET_HOSPITAL_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_HOSPITAL_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageHospitalSections = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST });            
      let _data;
      if(id){
        _data = await Axios.put(`/app/hospital/section/${id}`, form);
      }else{
        _data = await Axios.post(`/app/hospital/section`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      dispatch(getHospital(form?.hospitalId));
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageHospitalUser = (id, form) => {
  return async dispatch => {
    try{      
      dispatch({ type: dataConstants.MANAGE_HOSPITAL_USER_REQUEST });    
      let _data;
      if(id){
        _data = await Axios.put(`/app/hospital/user/${id}`, form);        
      }else{
        _data = await Axios.post(`/auth/hospital/register`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_USER_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      dispatch(getHospital(form?.hospitalId));
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_USER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getPatients = (page, limit, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_PATIENTS_REQUEST });      
      let URL = `/admin/patient?page=${page}&limit=${limit}`;
      if(filter?.query) URL += `&query=${filter?.query}`;
      if(filter?.gender) URL += `&gender=${filter?.gender}`;
      if(filter?.categoryId) URL += `&categoryId=${filter?.categoryId}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: dataConstants.GET_PATIENTS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_PATIENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const managePatient = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_PATIENT_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/admin/patient/${id}`, form);
      }else{
        _data = await Axios.post(`/admin/patient`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: dataConstants.MANAGE_PATIENT_SUCCESS,
        payload: {
          message: data?.message,
          data: data?.data
        }
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_PATIENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}