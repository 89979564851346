import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';

const icons = {
  AccountTreeOutlinedIcon,  
  DesignServicesOutlinedIcon
};

const pages = {
  id: "pages",
  title: "Administration",
  type: "group",
  children: [
    {
      id: "category",
      title: "Categories",
      type: "item",
      url: "/category",
      icon: icons.AccountTreeOutlinedIcon,
      breadcrumbs: true,
      roles: [1]
    }, 
    {
      id: "section",
      title: "Sections",
      type: "item",
      url: "/section",
      icon: icons.DesignServicesOutlinedIcon,
      breadcrumbs: true,
      roles: [1]
    },    
  ]
};

export default pages;