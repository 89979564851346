import { lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthForgotPassword = Loadable(lazy(() => import("pages/authentication/ForgotPassword")));
const AuthResetPassword = Loadable(lazy(() => import("pages/authentication/ResetPassword")));

const NonPrivateRoute = ({ children }) => {
  const { isAuthenticated, userLoading } = useSelector(state => state.user);
  return !userLoading && 
    (isAuthenticated ? 
      <Navigate to={`/`} />
    : children); 
}

const AuthRoutes = {
  path: "/auth/",
  element: <NonPrivateRoute><MinimalLayout/></NonPrivateRoute>,
  children: [
    {
      path: "login",
      element: <AuthLogin />
    },    
    {
      path: "forgot-password",
      element: <AuthForgotPassword />
    },
    {
      path: "reset-password",
      element: <AuthResetPassword />
    }
  ]
};

export default AuthRoutes;