import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  hospital: null,
  patientsData: null   
};

const dataReducer = (state = initState, action) => {  
  let index, temp;
  switch (action.type) {    
    case dataConstants.GET_HOSPITAL_REQUEST:
    case dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST:
    case dataConstants.MANAGE_HOSPITAL_USER_REQUEST:
    case dataConstants.GET_PATIENTS_REQUEST:
    case dataConstants.MANAGE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,        
      };

    case dataConstants.GET_HOSPITAL_SUCCESS:
      return {
        ...state,
        hospital: action.payload,        
        loading: false,
      };

    case dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS:
    case dataConstants.MANAGE_HOSPITAL_USER_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,        
        loading: false,
      };

    case dataConstants.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsData: action.payload,        
        loading: false,
      };

    case dataConstants.MANAGE_PATIENT_SUCCESS:
      temp = state.patientsData;
      index = temp?.patients?.findIndex(p => p.id === action.payload?.data?.id);
      if(index > -1){
        temp.patients[index] = action.payload?.data;
      }else{
        temp.patients.unshift(action.payload?.data);
      }
      return {
        ...state,
        patientsData: temp,        
        message: action.payload?.message,
        loading: false,
      };

    case dataConstants.GET_HOSPITAL_FAILURE:
    case dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE:
    case dataConstants.MANAGE_HOSPITAL_USER_FAILURE:
    case dataConstants.GET_PATIENTS_FAILURE:
    case dataConstants.MANAGE_PATIENT_FAILURE:
      return {
        ...state,
        error: action.payload,        
        loading: false,
      };
      
    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;