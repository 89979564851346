import { appConstants } from "redux/constants";
import Axios from "utils/axios";
import { loadUser } from "./user.action";

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/admin/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageCategory = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_CATEGORY_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/app/category/${id}`, form);
      }else{
        _data = await Axios.post(`/app/category`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: appConstants.MANAGE_CATEGORY_SUCCESS,
        payload: { data: data?.data, message: data?.message }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_CATEGORY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageSection = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_SECTION_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/app/section/${id}`, form);
      }else{
        _data = await Axios.post(`/app/section`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: appConstants.MANAGE_SECTION_SUCCESS,
        payload: { data: data?.data, message: data?.message }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_SECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageHospital = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_HOSPITAL_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/app/hospital/${id}`, form);
      }else{
        _data = await Axios.post(`/app/hospital`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: appConstants.MANAGE_HOSPITAL_SUCCESS,
        payload: { data: data?.data, message: data?.message }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_HOSPITAL_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateProfile = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PROFILE_REQUEST });
      const { data } = await Axios.put(`/auth/admin/profile`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PROFILE_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
      dispatch(loadUser())
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PROFILE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/admin/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}