import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const Category = Loadable(lazy(() => import("pages/category")));
const Section = Loadable(lazy(() => import("pages/section")));
const Hospital = Loadable(lazy(() => import("pages/hospital")));
const ManageHospital = Loadable(lazy(() => import("pages/hospital/manage")));
const Patient = Loadable(lazy(() => import("pages/patient")));
const Profile = Loadable(lazy(() => import("pages/profile")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : children); 
};

// const AuthorizedRoute = ({ roles, children }) => {
//   const { user } = useSelector(state => state.user);  
//   return user &&
//     (roles?.includes(user?.role) ? children : <Navigate to="/404" />);
// }

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "category",
      element: <Category/>
    },
    {
      path: "section",
      element: <Section/>
    },
    {
      path: "hospital",
      children: [
        {
          path: "",
          element: <Hospital/>
        },
        {
          path: ":id",
          element: <ManageHospital/>
        }
      ]
    },
    {
      path: "patient",
      element: <Patient/>
    },
    {
      path: "profile",
      children: [
        {
          path: "",
          element: <Profile/>
        },
        {
          path: "edit",
          element: <Profile/>
        }
      ]
    }    
  ]
};

export default MainRoutes;