import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  categories: [],
  sections: [],
  hospitals: [],
  redirect: ""  
};

const appReducer = (state = initState, action) => {    
  let temp, index = -1;
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:
    case appConstants.MANAGE_CATEGORY_REQUEST: 
    case appConstants.MANAGE_SECTION_REQUEST:          
    case appConstants.MANAGE_HOSPITAL_REQUEST:
    case appConstants.UPDATE_PROFILE_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,          
        sections: action.payload.sections,
        hospitals: action.payload.hospitals,
        loading: false,
      };    

    case appConstants.MANAGE_CATEGORY_SUCCESS:
      index = state.categories.findIndex(item => item.id === action.payload.data.id);
      if(index > -1){
        temp = [...state.categories];
        temp[index] = action.payload.data;
      }else{
        temp = [...state.categories, action.payload.data];
      }
      return {
        ...state,
        categories: temp,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.MANAGE_SECTION_SUCCESS:
      index = state.sections.findIndex(item => item.id === action.payload.data.id);      
      if(index > -1){
        temp = [...state.sections];
        temp[index] = action.payload.data;
      }else{
        temp = [...state.sections, action.payload.data];
      }
      return {
        ...state,
        sections: temp,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.MANAGE_HOSPITAL_SUCCESS:
      index = state.hospitals.findIndex(item => item.id === action.payload.data.id);
      if(index > -1){
        temp = [...state.hospitals];
        temp[index] = action.payload.data;
      }else{
        temp = [...state.hospitals, action.payload.data];
      }
      return {
        ...state,
        hospitals: temp,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.UPDATE_PROFILE_SUCCESS:
    case appConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.GET_INITIAL_DATA_FAILURE:            
    case appConstants.MANAGE_CATEGORY_FAILURE:
    case appConstants.MANAGE_SECTION_FAILURE:
    case appConstants.MANAGE_HOSPITAL_FAILURE:
    case appConstants.UPDATE_PROFILE_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;